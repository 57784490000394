html,
body {
  padding: 0;
  margin: 0;
}

/* this gets rid of extra whitespace in the body block */
body>img, body>iframe {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.react-horizontal-scrolling-menu--scroll-container {
  overflow-x: hidden;
}

.uploadcare--widget,
.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}